import React, { useEffect, useState, useRef } from "react";
import konsole from "./control/Konsole";
import { $CommonServiceFn } from "./network/Service";
import { $Service_Url } from "./network/UrlPath";
import { getApiCall,isNotValidNullUndefile } from "./Reusable/ReusableCom";
import { Col, Row, Table } from 'react-bootstrap';
import { $AHelper } from "./control/AHelper";
// import ReactToPrint from 'react-to-print';
import EmailImg from '../VectorEmail.png';
import PhoneImg from '../VectorPhone.png';
import PersonalDetailsIcon from '../VectorPersonalDetails.png';
import EmergencyIcon from '../VectorEmergencyContact.png';
import MedicalIcon from '../MedicalCondition.svg';
import PhysicianIcon from '../Physician.svg';
import InsuranceIcon from '../Insurance.svg';
import InsuranceProvIcon from '../VectorInsurance.png'
import OtherInfo from "./OtherInfo";
import medicationsIcon from '../Vector.svg';
import HealthInfo from "./HealthInfo";

const Emergencydata = ({cardData,primaryUserId}) => {

    const [userData,setUserdata] = useState({})
    const [emergencyContacts,setEmergencyContacts]=useState([])
    const [UserIssuanceDetails,setUserIssuanceDetails]=useState([])
    const [AllPhysician,setAllPhysician] =useState([])
    const [render,setrender] = useState(false)
    const pdfContainerRef = useRef(null);
    const [emergencyContactPriorities, setEmergencyContactPriorities] = useState([]);
    const [primaryData,setprimaryData] = useState({})
    const [bloodType,setbloodType] = useState([])
    const [getMedicationlist,setGetmedicationlist] = useState([])

    const [spousedata,setSpousedata] = useState({})

    
    useEffect(()=>{
        konsole.log(cardData,"cardData")
        getgetBloodTypefunc()
        getmemberdata(cardData.userId)
        // getmemberfamily(primaryUserId)
        GetUserIssuance(primaryUserId)
        fetchAndDisplaySearchList(primaryUserId)
        getmemberdata(primaryUserId)
        fetchEmergencyContactPriorities([]);
        GetMedication(cardData.userId)
    },[])

    useEffect(()=>{
        konsole.log(primaryData?.userId,primaryData?.userId != 'undefined',"primaryDatauserId")
        if(primaryData?.userId != undefined){
        getmemberfamily(primaryUserId)
        }
    },[primaryData?.userId])

        const getmemberdata = (userId) => {
            $CommonServiceFn.InvokeContractApi('GET',$Service_Url.getFamilyMemberbyID+userId,'',(response,error) => {
                if(response){
                    konsole.log(response,"responseuser")
                    // setUserdata(response.data.data.member)
                    // getcontactdeailswithOthger(response.data.data.member,userId)
                    if(userId == primaryUserId){
                        setprimaryData(response.data.data.member)
                        if(userId==cardData.userId){
                            setUserdata(response.data.data.member)
                        }
                    }
                    else{
                        setUserdata(response.data.data.member)
                        getcontactdeailswithOthger(response.data.data.member,userId)
                    }
                }
            })
        }
        
        const getmemberfamily = (userId) => {
            $CommonServiceFn.InvokeContractApi('GET',$Service_Url.getFamilyMembers+userId,'',(response,error) => {
                if(response){
                    konsole.log(response,"responseusergetFamilyMembers")
                    if(primaryData){
                        response.data.data.unshift({'userId':primaryData.userId,'fName':primaryData.fName,'lName':primaryData.lName,'relationshipTypeId':'1','relationshipName':'Primary','isEmergencyContact':primaryData.memberRelationship?.isEmergencyContact})
                        }
                    konsole.log(response,primaryData,"responseusergetFamilyMembers")
                    let spouseData = response.data.data.filter((e)=> e.userId == primaryData.spouseUserId)
                    setSpousedata(spouseData[0])
                    response.data.data.filter(e=> e.userId != cardData.userId).map((data)=>{
                    if(data.userId != cardData.userId ){
                    konsole.log(data.userId,cardData.userId,response.data.data.filter(e=>e.userId != cardData.userId), cardData.emergencyContacts.filter(e=>e.userId != cardData.userId),"data.userIdcardData.userId")
                    cardData.emergencyContacts.sort((a, b) => a?.emerContactPriorityId - b?.emerContactPriorityId).filter(e=>e.userId != cardData.userId).map((e)=>{
                    if((data.userId == e.emergencyContactUserId) && data.isEmergencyContact==true){
                        // setEmergencyContacts((emergencyContacts)=>[...emergencyContacts,data])
                         data['emerContactPriorityId'] = e.emerContactPriorityId
                        getcontactdeailswithOthger(data,e.emergencyContactUserId)
                        fetchSavedAddress(e.emergencyContactUserId)
                        setrender(!render)
                    }
                
                    })
                }
                })
                konsole.log(response.data.data,"response.data.data")
                }
            })
        }


        const getcontactdeailswithOthger = (data,userIds) => {
            $CommonServiceFn.InvokeContractApi("GET", $Service_Url.getcontactdeailswithOther + userIds, "", (response, error) => {
                if (response) {
                    konsole.log("getcontactdeailswithOtherres", response?.data?.data,emergencyContacts)
                    let responseData = response?.data?.data
                    if(userIds == cardData.userId){
                      let UaseDatas =  data
                      konsole.log(UaseDatas,userData,data,"UaseDatas")
                      UaseDatas['contact']=response?.data?.data
                      konsole.log(UaseDatas,userData,"UaseDatas")
                      setUserdata(UaseDatas)
                    }else{
                    let myNewArray = [...emergencyContacts]
                    // let arrFindIndex = myNewArray?.findIndex(({ userId }) => userId == userIds)
                    // konsole.log("arrFindIndex1212",arrFindIndex,myNewArray,userIds)
                    // myNewArray[arrFindIndex]['contact'] = response?.data?.data
                    let UaseDatas =  data
                      konsole.log(UaseDatas,userData,data,"UaseDatas")
                      UaseDatas['contact']=response?.data?.data
                    setEmergencyContacts((e)=>[...e,UaseDatas] )
                    }

                } else {
                    konsole.log("getcontactdeailswithOthererr", response)
                }
            })
        }

        const fetchSavedAddress = (userIds) => {
            $CommonServiceFn.InvokeContractApi("GET", $Service_Url.getAllAddress + userIds,
                "", (response) => {
                    if (response) {
                        konsole.log("resposejjAddress", response.data.data)
                        let myNewArray = [...emergencyContacts]

                        let arrFindIndex = myNewArray?.findIndex(({ userId }) => userId == userIds)
                        myNewArray[arrFindIndex]['address'] = response.data.data
                        konsole.log("resposejjAddress", myNewArray,response.data.data)

                        setEmergencyContacts(myNewArray )
                    }
                    else {
                        //   this.toasterAlert(Msg.ErrorMsg,"Warning")
                    }
                })
        }

        const GetUserIssuance = async(userId) => {
           await getApiCall("GET",$Service_Url.getUserInsurance + userId,setUserIssuanceDetails)
        };

        const GetMedication = async(userId) => {
            await getApiCall("GET",$Service_Url.getUserMedication + userId,setGetmedicationlist)
         };

        const fetchAndDisplaySearchList = async(userid) => {
            const userId = userid || this.state.userId;
            const sendData = `?MemberUserId=${userId}&ProTypeId=${10}&primaryUserId=${userId}`
          const result=  await getApiCall("GET",$Service_Url.getSearchProfessional + sendData,setAllPhysician)
          if(result!=='err'){
            fetchSavedAddress(userId)
          }


        };

        const fetchEmergencyContactPriorities = () => {
            $CommonServiceFn.InvokeContractApi('GET', $Service_Url.getEmergencyPriority,'', (response) => {
                konsole.log(response, "responseemergencypriority")
                setEmergencyContactPriorities(response.data.data)
            })
        }

        const getPriorityLabel = (index) => {
            // const priorityId = emergencyContactPriorities;
            if(index){
            return emergencyContactPriorities.length > 0 ? emergencyContactPriorities[index-1].label : 'N/A';
            }
        };
        

        const returnLabelVal=(label,value)=>{
            return(
                <> <p>{label}</p> <p>{value}</p> </>
            )
        }

        const getgetBloodTypefunc = () => {
            $CommonServiceFn.InvokeContractApi('GET', $Service_Url.getBloodType, '', (response) => {
                konsole.log(response, "responsegetbloodtype")
                    setbloodType(response.data.data)
            })
        }

        function removeDuplicateObjects(arr) {
            const uniqueObjects = [];
            const uniqueObjectStrings = new Set();
          
            for (const obj of arr) {
              const objString = JSON.stringify(obj);
          
              if (!uniqueObjectStrings.has(objString)) {
                uniqueObjectStrings.add(objString);
                uniqueObjects.push(obj);
              }
            }
            return uniqueObjects;
        }
          
        const getLabelColor = (index) => {
            const colors = ['#D70101', '#CF8246', '#439800'];
        
            return index < colors.length ? colors[index] : '';
        };
        
        //Print Button
        // const reactToPrintTrigger = React.useCallback(() => {
        //     return (
        //         <div className="d-flex justify-content-center mt-5">
        //             <button className="EmergencyDataTablet px-5 fs-4 fw-bold">Print</button>
        //         </div>
        //     );
        // }, []);

        const reactToPrintContent = React.useCallback(() => {
            let elem = pdfContainerRef.current;
            let domClone = elem.cloneNode(true);
            let $printSection = document.createElement("div");
            let $printImg = document.getElementsByTagName('img')
            $printSection.id = "printSection";
            $printSection.innerHTML = "";
            $printSection.appendChild(domClone);
            $printSection.style.width = "298mm"; /* DIN A4 standard paper size */

        
            // Calculate the height of the content
            let contentHeight = $printSection.scrollHeight;
          
            // Set the height of the $printSection element to match the height of the content
            $printSection.style.height = contentHeight + "px";
          
            // Set the padding of the $printSection element
            $printSection.style.padding = "0 50px";
          
            return $printSection;
          }, []);
          
          
          
        konsole.log(userData,"userData")             
    return(
    <>
        <div className="EmergencyDataMain" id="EmergencyDataMainPdfDown"ref={pdfContainerRef}>
            <Row className="justify-content-start align-items-center mx-auto ps-0">
                <Col xs={11} sm={11} className="EmergencyDataUserName ps-2">
                    <p className="fw-bolder"> {(isNotValidNullUndefile(userData?.fName)) ? <>{userData?.fName + ' ' + userData?.lName}</>:""}</p>
                </Col>
            </Row>
            <Row className="justify-content-between align-items-end border-bottom mx-auto">
            {(userData.contact?.contact?.emails?.[0]?.emailId || userData.contact?.contact?.mobiles?.[0]?.mobileNo) ? (
            <>
                <Col xs={11} sm={2} xl={3} className="pt-3 EmergencyDataUser pt-0">
                    <p className="EmergencyDataRec">Patient name<span style={{fontWeight:'bold'}} className="ps-2">&#x21e1;</span></p>
                </Col>
                {userData.contact?.contact?.emails?.[0]?.emailId && (
                <Col xs={11} sm={3} xl={3} className="p-3 d-flex align-items-center">
                    <div style={{ border: '1px solid #D8D8D8', padding: '5px', borderRadius:'50%',display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <img style={{ width: '10px', height: '10px' }} src={EmailImg} alt="Email Icon" />
                    </div>
                    <p className="EmergencyDataRec ps-2">{userData.contact?.contact?.emails?.[0]?.emailId}</p>
                </Col>
                )}

                {userData.contact?.contact?.mobiles?.[0]?.mobileNo && (
                <Col xs={11} sm={4}xl={3} className="p-3 d-flex align-items-center">
                    <div style={{ border: '1px solid #D8D8D8', padding: '5px', borderRadius: '50%',display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <img style={{ width: '10px', height: '10px' }} src={PhoneImg} alt="Phone Icon" />
                    </div>
                    <p className="EmergencyDataRec ps-2">{userData.contact?.contact?.mobiles?.[0]?.mobileNo.slice(0, -10)} {$AHelper.formatPhoneNumber(userData.contact?.contact?.mobiles?.[0]?.mobileNo.slice(-10))}</p>
                </Col>
                )}
                </>
                ) : (
                    <Col xs={10} sm={10} xl={10} className="p-2 EmergencyDataUser pt-0">
                        <p className="EmergencyDataRec pe-5">Patient name<span style={{ fontWeight: 'bold' }}>&#x21e1;</span></p>
                    </Col>
                )}
            </Row>

            <Row className="">
                <Row className=" px-2 mx-auto">
                    <Col xs={11} sm={10} className="EmergencyDataUser align-items-center d-flex p-3 pb-0">
                        <div>
                            <img style={{ width: '14px', height: '14px' }} src={PersonalDetailsIcon} alt="PersonalDetails Icon" />
                        </div>
                        <p className="Heading fw-bold text-center ps-2">Personal Details</p>
                    </Col>
                </Row>
                    <Row className="justify-content-between align-items-center px-2 border-bottom mx-auto">
                        <Col xs={11} sm={2} className="p-3">
                            <p className="EmergencyDataUserMain">First name</p>
                            <p className="EmergencyDataRec">{userData?.fName}</p>
                        </Col>
                        <Col xs={11} sm={2} className="p-3">
                            <p className="EmergencyDataUserMain">Middle name</p>
                            <p className="EmergencyDataRec">{userData?.mName}</p>
                        </Col>
                        <Col xs={11} sm={2} className="p-3">
                            <p className="EmergencyDataUserMain">Last name</p>
                            <p className="EmergencyDataRec">{userData?.lName}</p>
                        </Col>
                    </Row>
                    <Row className="justify-content-between align-items-center px-2 border-bottom mx-auto">
                        <Col xs={11} sm={2} className="p-3">
                            <p className="EmergencyDataUserMain">Date of Birth(DOB)</p>
                            <p className="EmergencyDataRec">{userData?.dob?$AHelper.getFormattedDate(userData?.dob):"N/A" }</p>
                        </Col>
                        <Col xs={11} sm={2} className="p-3">
                            <p className="EmergencyDataUserMain">Gender</p>
                            <p className="EmergencyDataRec">{userData?.genderId == 1 ? 'Male' : userData?.genderId == 2 ? 'Female' :'N/A'}</p>
                        </Col>
                        <Col xs={11} sm={2} className="p-3">
                        <p className="EmergencyDataUserMain">Blood Type</p>
                        <p className="EmergencyDataRec">{bloodType?.filter((e)=>e.value==cardData.bloodTypeId)[0]?.label}</p>
                        </Col>
                    </Row>
            </Row>

            <Row className="mx-auto">
                <Row className=" mx-auto">
                    <Col xs={11} sm={10} className="EmergencyDataUser d-flex align-items-center p-3 ps-2 pb-0">
                    <div>
                        <img style={{ width: '16px', height: '16px' }} src={EmergencyIcon} alt="PersonalDetails Icon" />
                    </div>
                    <p className="Heading fw-bold ps-2">Emergency Contacts ({removeDuplicateObjects(emergencyContacts).length})</p>
                    </Col>
                </Row>

                <Row className="text-center border-bottom mx-auto ps-5 d-none d-sm-flex">
                    <Col xs={12} sm={3} className="EmergencyData p-3">
                    <p className="EmergencyDataUserMain">Contact Name</p>
                    </Col>
                    <Col xs={12} sm={3} className="EmergencyData p-3">
                    <p className="EmergencyDataUserMain">Phone number</p>
                    </Col>
                    <Col xs={12} sm={3} className="EmergencyData p-3">
                    <p className="EmergencyDataUserMain">Relation</p>
                    </Col>
                    <Col xs={12} sm={3} className="EmergencyData p-3">
                    <p className="EmergencyDataUserMain">Contact priority</p>
                    </Col>
                </Row>

                {removeDuplicateObjects(emergencyContacts).sort((a, b) => a?.emerContactPriorityId - b?.emerContactPriorityId).map((e, index) => (
                <Row className="text-center align-items-center border-bottom mx-auto" key={e.id}>
                    <Col xs={12} sm={3} className="EmergencyDataRec custom-left-padding align-items-center d-inline-flex justify-content-start p-3 d-none d-sm-inline-flex justify-content-center">
                        <div className="ps-5">
                        <img style={{ width: '13px', height: '13px' }} src={PersonalDetailsIcon} alt="PersonalDetails Icon" />
                        </div>
                        <p className="ps-1 m-0 align-self-center">{e.fName+' '+e.lName}</p>
                    </Col> 


                    <Col xs={12} className="EmergencyDataRec custom-left-padding align-items-center justify-content-start p-3 d-sm-none">
                        <div className="ps-5 d-none d-sm-flex">
                        <img style={{ width: '13px', height: '13px' }} src={PersonalDetailsIcon} alt="PersonalDetails Icon" />
                        </div>
                        <p className="EmergencyDataUserMain">Contact Name</p>
                        <p className="ps-1 m-0 align-self-center">{e.fName+' '+e.lName}</p>
                    </Col>

                    <Col xs={12} sm={3} className="EmergencyDataRec p-3 justify-content-center">
                        <p className="EmergencyDataUserMain d-flex d-sm-none justify-content-center">Phone number</p>
                        <p>{e?.contact?.contact?.mobiles[0]?.mobileNo.slice(0, -10)} {$AHelper.formatPhoneNumber(e?.contact?.contact?.mobiles[0]?.mobileNo.slice(-10)) || 'N/A'}</p>
                    </Col>

                    <Col xs={12} sm={3} className="EmergencyDataRec p-3 justify-content-center">
                        <p className="EmergencyDataUserMain d-flex d-sm-none justify-content-center">Relation</p>
                        <p>{e.relationshipName ? e.relationshipName : 'Primary'}</p>
                    </Col>

                    <Col xs={12} sm={3} className="EmergencyDataRec p-3 justify-content-center">
                    <p className="EmergencyDataUserMain d-flex d-sm-none justify-content-center">Contact priority</p>
                        <label style={{ color: getLabelColor(e?.emerContactPriorityId-1) }}>
                        <input
                            className="m-3"
                            type="radio"
                            value="yes"
                            checked={true}
                            name={true}
                            readOnly
                            style={{ accentColor: getLabelColor(e?.emerContactPriorityId-1) }}
                        />
                        {getPriorityLabel(e?.emerContactPriorityId)}
                        </label>
                    </Col>
                </Row>
                ))}
            </Row>
            {konsole.log(cardData, 'shshdhshshshshsh')}
            <Row className="">
                <Col xs={11} sm={10} className="EmergencyDataUser d-flex align-items-center p-3">
                    <div className="pe-2">
                        <img style={{ width: '18px', height: '18px' }} src={MedicalIcon} alt="PersonalDetails Icon" />
                    </div>
                    <p className="Heading fw-bold">Medical Conditions:</p>
                </Col>

            <Col xs={12} sm={3} md={3} className="EmergencyDataUserData">
                <Row className="EmergencyDataUser justify-content-around align-items-center mx-auto">
                    <Col xs={12} sm={12} className="p-0">
                        <ul>
                        <li className="EmergencyDataRec">Organ Donor:</li>
                        </ul>
                    </Col>
                    <Col xs={12} sm={12} className="text-md-start">
                        <label className="d-inline-flex ps-3">
                        <input
                            className="mx-2 EmergencyDataRec"
                            type="radio"
                            value="yes"
                            name="organDonor"
                            checked={cardData.isOrganDonor === true}
                            readOnly
                        /> Yes
                        </label>
                        <label className="d-inline-flex">
                        <input
                            className="mx-2 EmergencyDataRec"
                            type="radio"
                            value="no"
                            name="organDonor"
                            checked={cardData.isOrganDonor === false}
                            readOnly
                        /> No
                        </label>
                    </Col>
                </Row>

                {/* <Row className="EmergencyDataUser justify-content-around align-items-center mx-auto">
                    <Col xs={12} sm={12} className="p-0">
                        <ul>
                        <li className="EmergencyDataRec">Major illness & injuries:</li>
                        </ul>
                    </Col>
                    <Col xs={12} sm={12} className="text-md-start">
                        <p className="EmergencyDataRec" style={{ color: '#939393', paddingLeft:'30px' }}>{cardData?.addinationalInfo}</p>
                    </Col>
                </Row> */}
            </Col>

            <Col xs={12} sm={3} md={6} className="EmergencyDataUserData" style={{overflowX:'auto'}}>
                <Row className="EmergencyDataUser justify-content-around align-items-center mx-auto">
                    <Col xs={12} sm={12} className="p-0">
                        <ul>
                        <li className="EmergencyDataRec">Current medical conditions:</li>
                        </ul>
                    </Col>
                    <Col xs={12} sm={12} className="text-md-start">
                        <p className="EmergencyDataRec" style={{ color: '#939393', paddingLeft:'30px'  }}>{cardData?.medicalConditions}</p>
                    </Col>
                </Row>
            </Col>

            <Col xs={12} sm={3} md={3} className="EmergencyDataUserData">
                <Row className="EmergencyDataUser justify-content-around align-items-center mx-auto">
                <Col xs={12} sm={12} className="p-0">
                    <ul>
                    <li className="EmergencyDataRec">Allergies to medicines:</li>
                    </ul>
                </Col>
                <Col xs={12} sm={12} className="text-md-start">
                    <Row className="d-inline-flex" style={{paddingLeft:'30px'}}>
                        <div className="EmergencyDataTablet">
                            <p className="m-0">{cardData?.allergiesToMedications}</p>
                        </div>
                    </Row>
                </Col>
                </Row>
            </Col>
            </Row>
            {getMedicationlist.userMedications?.length > 0 && <Row className="border-top w-100 pt-4">
            <Row className=" mx-auto w-60 my-4" xs={12} sm={12} md={10} style={{width:"50% !important"}}>
                <div style={{overflowX:'auto'}}>

                <h5 className="Heading fw-bold">
                <img src={medicationsIcon} width='17px' className="me-1" /> Medications :</h5>
                <Table bordered className="table-responsive financialInformationTable mt-4">
                <thead className='text-center align-middle' >
                <tr  >
                <th  >Medication</th>
                <th  >Dosage</th>
                <th  >Frequency</th>
                <th  >Timing</th>
                {/* <th  >Start Date</th> */}
                {/* <th  >End Date</th> */}
                <th  >Note</th>
                </tr>
                </thead>
                <tbody>
                {getMedicationlist.userMedications?.map((e,ind)=>(<>
                <tr  style={{wordBreak:"break-word", textAlign:'center'}}className="mb-5">
                <td style={{wordBreak:"break-word"}}>
                <OtherInfo
                    key={ind}
                    othersCategoryId={38} 
                    userId={cardData.userId}
                    othersMapNatureId={e.userMedicationId} 
                    FieldName={e.medicationName}
                    />
                </td>
                <td style={{wordBreak:"break-word"}}>{e.doseAmount}</td>
                <td style={{wordBreak:"break-word"}}>{e.frequency}</td>
                <td style={{wordBreak:"break-word"}}>{e.time}</td>
                {/* <td style={{wordBreak:"break-word"}}>{e.startDate != null && $AHelper.getFormattedDate(e.startDate)}</td> */}
                {/* <td style={{wordBreak:"break-word"}}>{e.endDate != null && $AHelper.getFormattedDate(e.endDate)}</td> */}
                <td style={{ width: '40%' }}>
                <p className="para-p1 pb-1 EmergencyDataRec mt-4 padding-8 ps-2" style={{ width: '100%' }}>
                {e.doctorPrescription}
                </p>
                </td>
                </tr>
                </>
                ))}
                </tbody>
                </Table>
                </div>
            </Row>
            </Row>}


            <Row className="mx-auto">
            <Row className=" align-items-center px-2">
                <Col xs={11} sm={10} className="EmergencyDataUser d-flex p-3 pb-0">
                    <div>
                        <img style={{ width: '22px', height: '22px' }} src={InsuranceIcon} alt="PersonalDetails Icon" />
                    </div>
                    <p className="Heading fw-bold ps-2">Insurance Details</p>
                </Col>
            </Row>
            
            <Row className="justify-content-around align-items-center px-2 border-bottom mx-auto d-none d-sm-flex">
                <Col xs={11} sm={2} className="p-3">
                    <p className="EmergencyDataUserMain">Insurance Provider</p>
                </Col>
                <Col xs={11} sm={2} className="p-3">
                    <p className="EmergencyDataUserMain">Policy Number</p>
                </Col>
                {/* <Col xs={11} sm={2} className="p-3">
                    <p className="EmergencyDataUserMain">Contact Details</p>
                </Col> */}
            </Row>
            {UserIssuanceDetails.map((e) => (
                <Row className="justify-content-around align-items-center px-2 border-bottom mx-auto d-flex d-sm-flex">
                    <Col xs={11} sm={2} className="custom-left-padding d-sm-inline-flex justify-content-start p-3">
                        <div className="pe-2 d-none d-sm-flex">
                            <img style={{ width: '13px', height: '13px' }} src={InsuranceProvIcon} alt="PersonalDetails Icon" />
                        </div>
                        <p className="EmergencyDataUserMain d-flex d-sm-none">Insurance Provider</p>
                        <p className="EmergencyDataRec">{e?.insComName ? e?.insComName : 'N/A'}</p>
                    </Col>
                    <Col xs={11} sm={2} className="p-3">
                        <p className="EmergencyDataUserMain d-flex d-sm-none">Policy Number</p>
                        <p className="EmergencyDataRec">{e?.insCardPath1 ? e?.insCardPath1 : 'N/A'}</p>
                    </Col>
                </Row>
            ))}
            </Row>

            {(cardData.userId == primaryData.userId || cardData.userId == spousedata.userId) && <HealthInfo primaryData={cardData.userId == primaryData.userId ? primaryData : cardData.userId == spousedata.userId ? spousedata : {}} />}
        
        </div>
    </>
    )
}
export default Emergencydata;
