import React from 'react';
import konsole from '../Components/control/Konsole';
import HealthInsurencePlanClientSumComponent from './HealthInsurencePlanClientSumComponent';
import PersonalDetailsIcon from '../VectorPersonalDetails.png';

const HealthInsurencePlanSumComponent = (props) => {
  const healthInsurence=props.healthInsurence?.data?.data
  const secondhealthInsurance=props.secondhealthInsurance?.data?.data
  console.log("YTYTYTYTYT",props)

  let userDetailOfPrimary=props?.userDetailOfPrimary

  return (
    <div className="contain padding-8">
      <div>
      <ul className="pt-3 ps-2"><li className="fw-bold">Health Insurance Plan</li></ul>
      </div>

      <div className="contain">
        <div className="d-flex">
          <div className="col">
            <div className="d-flex gap-2 pb-2 pt-2" style={{borderBottom:"1px solid #E8E8E8", margin:"0px 10px"}}>
             <img className="mt-0 mb-1" style={{ width: '14px', height: '14px' }} src={PersonalDetailsIcon} />
             <h5 className="healthPrimary">{userDetailOfPrimary.memberName}</h5>
            </div>
           
            {healthInsurence ? <HealthInsurencePlanClientSumComponent userId={props.memberId} healthInsurence={healthInsurence[0]} userNo={1} ></HealthInsurencePlanClientSumComponent> : <p className="mx-3"> (Not provided) </p>}
          </div>
          {userDetailOfPrimary.spouseName  && <><div className="col" style={{borderLeft:"2px solid #E8E8E8"}}>
          <div className="d-flex gap-2 ps-2 pb-4 pt-3" style={{borderBottom:"1px solid #E8E8E8", margin:"0px 10px"}}>
             <img className="mt-0 mb-1" style={{ width: '14px', height: '14px' }} src={PersonalDetailsIcon} />
             <h5 className="healthPrimary">{userDetailOfPrimary.spouseName}</h5>
            </div>
            
            {secondhealthInsurance ? <HealthInsurencePlanClientSumComponent userId={props.spouseId} healthInsurence={secondhealthInsurance[0]} userNo={2} > </HealthInsurencePlanClientSumComponent> : <p className="mx-3"> (Not provided) </p>}
          </div>
          </>}
        </div>
      </div>
    </div>
  );
};

export default HealthInsurencePlanSumComponent;
