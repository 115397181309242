import React from 'react';
import konsole from "../Components/control/Konsole";
import OtherInfo from './OtherInfo';
import { uscurencyFormate } from '../Components/control/Constant';


const HealthInsurencePlanClientSumComponent = (props) => {
  const healthInsurence=props.healthInsurence
  const secondhealthInsurance=props.secondhealthInsurance
  const primaryUserId = props.userId
  const spouseUserId = props.userId;
  console.log("healthInsurence",healthInsurence,props)
  return (
    <div className=" mb-5 d-flex w-100 justify-content-between" >
        <div>
          <div className=" d-flex  pt-3" style={{gap:"6rem"}}>

            <div className="sumPhysician">
              <p>Plan Type</p>
              <h5><OtherInfo othersCategoryId={31} othersMapNatureId={healthInsurence?.userInsuranceId} FieldName={healthInsurence?.typePlan} userId={props.userNo == 2 ? spouseUserId : primaryUserId} /></h5>
            </div>
          </div>
            <div className=" pt-3">
              <div className="sumPhysician">
                <p>Supplement Insurance</p>
                <h5><OtherInfo othersCategoryId={30} othersMapNatureId={healthInsurence?.userInsuranceId} FieldName={healthInsurence?.suppPlan} userId={props.userNo == 2 ? spouseUserId : primaryUserId} /></h5>
              </div>
            </div>
            
          <div className=" pt-3">
            <div className="sumPhysician">
              <p>Insurance Name</p>
              <h5>{healthInsurence?.insName}</h5>
            
          </div>
          <div className="sumPhysician pt-3">
              <p>Policy No.</p>
              <h5>{healthInsurence?.insCardPath1}</h5>
            </div>
            </div>
        </div>

        <div className=" pt-3">
            <div className="sumPhysician">
              <p>Premium Amount</p>
              <h5>{uscurencyFormate(healthInsurence?.premiumAmt)}</h5>
            </div>
            <div className="sumPhysician pt-3">
              <p>Premium Frequency</p>
              <h5>{healthInsurence?.premiumFrePerYear}</h5>
            </div>
              <div className="sumPhysician pt-3">
                <p>Company Name</p>
                <h5><OtherInfo othersCategoryId={35} othersMapNatureId={healthInsurence?.userInsuranceId} FieldName={healthInsurence?.insComName} userId={props.userNo == 2 ? spouseUserId : primaryUserId} /></h5>
              </div>
          </div>   
    </div>
  );
};

export default HealthInsurencePlanClientSumComponent;

