import { useEffect, useState } from "react";
import Api from '../Components/control/api'
// import { health } from '../../../control/Constant'
import konsole from '../Components/control/Konsole'
import ClientFormSumComponent from './ClientFormSumComponent'
import CommProfSum from "./CommProfSum"; 
import HealthInsurencePlanSumComponent from "./HealthInsurencePlanSumComponent";
import FamilyMedicalSumHistoryComponent from "./FamilyMedicalSumHistoryComponent";
import PersonalDetailsIcon from '../VectorPersonalDetails.png';
import HealthIcon from '../healthblack.svg'

const HealthInfo = ({primaryData, spouseData,refrencePage}) => {
  const userId = primaryData?.userId 
  const spouseId = spouseData?.userId
  const primaryPhysicanDetails = "";
  const api = new Api();
console.log(primaryData, spouseData,refrencePage,'{primaryData, spousedata,refrencePage')
  const [primaryPersonalMed, setprimaryPersonalMed] = useState([])
  const [spousePersonalMed, setspousePersonalMed] = useState([])

  let [primaryCareMember, setPrimaryCareMember] = useState();
  let [primarySpouseMember, setPrimarySpouseMember] = useState();
  let [healthInsurence, setHealthInsurence] = useState();
  let[secondhealthInsurance, setsecondHealthInsurance] = useState();
  let [familyMedicalHistory,setFamilyMedicalHistory]=useState();
  let [spouseFamilyMedicalHistory,setSpouseFamilyMedicalHistory]=useState()
  let [spouseMedicalHistory,setSpouseMedicalHistory]=useState()
  let [personalMedicalHistory,setPersonalMedicalHistory]=useState()
  let [currentLifeStyle,setCurrentLifeStyle]=useState()
  let [spouseCurrentLifeStyle,setSpouseCurrentLifeStyle]=useState()

  let [personalLifestyle,setPersonalLifestyle]=useState()
  let [spouseLifestyle,setSpouseLifestyle]=useState()

  let [addtionalPhysicanDetailsPrimary,setAddtionPhysicanDetailsPrimary]=useState([]);
  let [addtionalPhysicanDetailsSpouse,setAddtionPhysicanDetailsSpouse]=useState([]);
  let userDetailOfPrimary={
    memberName: primaryData?.fName+" "+primaryData?.lName,
    spouseName: spouseData?.fName != undefined ? spouseData?.fName+" "+spouseData?.lName : null
  }

  const category1ID=1  //represents the categoryID used to fetch the current lifestyle
  let arr=[]
  

  useEffect(()=>{
    // console.log("YTYTYTYTYT",healthInsurence)
   
   

  },[])

  useEffect(() => {
    let tempSpecialCarePhysicans=[]
    if(userId !== ""){
      api.GetUserSubject(userId, 0, 2, 0)
        .then((res) => {
          if (res) {
            konsole.log("zdvgaszgh", res.data.data.userSubjects)
            setPersonalLifestyle(res?.data?.data?.userSubjects)
          }
        }).catch(error => {
          konsole.log("error", error.response);
        })
      api.GetUserSubject(userId, 0, 3, 0)
      .then((res) => {
        if (res) {
          setprimaryPersonalMed(res?.data?.data?.userSubjects)
        }
      }).catch(error => {
        konsole.log("error", error.response);
      })
        api.GetPrimaryCareMembersByPrimaryUserId(userId).then((res)=>{
          if(res){
            res.data.data.physicians.map((item)=>{
              if(item.is_Primary==false){
                tempSpecialCarePhysicans.push(item)
              }
            })
            setAddtionPhysicanDetailsPrimary(tempSpecialCarePhysicans)
          }
        }).catch((error)=>{
          konsole.log("error",error)
        })
      api.GetPrimaryCareMembersByPrimaryUserId(userId).then((res) => {
        if (res) {
          const primaryCareMemberUser = res?.data?.data?.physicians?.filter(ele => ele?.is_Primary_Care == true);
          // konsole.log("sdghagdhja", res, primaryCareMemberUser)
          setPrimaryCareMember(primaryCareMemberUser?.[0]);
        }
      }).catch(error => {
        konsole.log("error", error.response);
      })
      api.GetUserInsuranceByUserid(userId).then((res) => {
        if (res) {
          setHealthInsurence(res);
        }
      }).catch(error => {
        konsole.log("error", error.response);
      })

      api.GetUserInsuranceByUserid(spouseId).then((res) => {
        if (res) {
          setsecondHealthInsurance(res);
        }
      }).catch(error => {
        konsole.log("error", error.response);
      })
      
      api.UserMedHistoryByMedHisType(userId, 2)
        .then((res) => {
          if (res) {
            setFamilyMedicalHistory(res.data.data.userMedHistory)

          }
        }).catch(error => {
          konsole.log("error", error.response);
        })
      api.UserMedHistoryByMedHisType(userId,1)
         .then((res)=>{
           if(res){
            setPersonalMedicalHistory(res.data.data.userMedHistory)
           }
         }).catch((error)=>{
          konsole.log("error",error.response)
         })
    }
  }, [userId]);

  useEffect(()=>{
    let tempSpecialCarePhysicans = [];
    if(spouseId !== ""){
      api.GetUserSubject(spouseId, 0, 2, 0)
        .then((res) => {
          if (res) {
            setSpouseCurrentLifeStyle(res.data.data.userSubjects)
          }
        }).catch(error => {
          konsole.log("error", error.response);
        })
      api.GetUserSubject(spouseId, 0, 3, 0)
      .then((res) => {
        if (res) {
          setspousePersonalMed(res?.data?.data?.userSubjects)
        }
      }).catch(error => {
        konsole.log("error", error.response);
      })
      konsole.log("123qweasdzxc",spouseLifestyle)
      api.GetPrimaryCareMembersByPrimaryUserId(spouseId).then((res) => {
        if (res) {
          const primaryCareMemberUser = res?.data?.data?.physicians?.filter(ele => ele?.is_Primary_Care == true);
          // konsole.log("sdghagdhja", res, primaryCareMemberUser)
          setPrimarySpouseMember(primaryCareMemberUser?.[0]);
        }
      }).catch(error => {
        konsole.log("error", error.response);
      })
      api.UserMedHistoryByMedHisType(spouseId, 2).then((res) => {
        if (res) {
          setSpouseFamilyMedicalHistory(res.data.data.userMedHistory)
        }
      }).catch(error => {
        konsole.log("error", error.response);
      })
      api.UserMedHistoryByMedHisType(spouseId,1).then((res)=>{
        if(res){
          setSpouseMedicalHistory(res.data.data.userMedHistory)
        }
      }).catch((error)=>{
        konsole.log("error",error.response)
      })
      api.GetPrimaryCareMembersByPrimaryUserId(spouseId).then((res)=>{
        if(res){
          res.data.data.physicians.map((item)=>{
            if(item.is_Primary==false){
              tempSpecialCarePhysicans.push(item)
            }
          })
          setAddtionPhysicanDetailsSpouse(tempSpecialCarePhysicans)
        }
      }).catch((error)=>{
        konsole.log("error",error)
      })
    }
  },[spouseId])

  return (
    <div className="mt-4">
      <div className=" d-flex justify-content-start pb-2 padding-8" style={{borderBottom:"1px solid #E8E8E8"}}>
      <img className="mt-0 mb-1" style={{ width: '18px', height: '18px' }} src={HealthIcon} />
        <h5 className="fw-bold ms-2">Health Information :</h5>
      </div>
      {(refrencePage !="SummaryDoc") && 
      <div className="mt-4">
        <p className="para-p1 pb-1 EmergencyDataRec mt-4 padding-8 ps-2">
          Please provide information for each of your healthcare providers. This
          information supplies a frame of reference when creating a coordinated<br />
          LifePlan with regards to Health Issues in retirement.
        </p>
      </div>
      }
      <div className="padding-8">
       <ul className="ps-2 pt-3"><li className="fw-bold">Primary Care Physician</li></ul> 
      
      <div className=" ms-1 d-flex gap-2 pb-3 align-items-center padding-8" style={{borderBottom:"1px solid #E8E8E8"}}>
        <img className="mt-0 mb-1" style={{ width: '14px', height: '14px' }} src={PersonalDetailsIcon} />
        <h5 className="healthPrimary">{userDetailOfPrimary?.memberName}</h5>
      </div>
      {(primaryCareMember && api) ? (
        <ClientFormSumComponent
         primaryCareMember={primaryCareMember}
         api={api}>
        </ClientFormSumComponent>
      ) : <p className="mx-3"> (Not provided) </p>}

      {spouseId && <><div>
      <div className="ms-1 d-flex gap-2 pb-4 pt-3 align-items-center padding-8" style={{borderBottom:"2px solid #E8E8E8"}}>
        <img className="mt-0 mb-1" style={{ width: '14px', height: '14px' }} src={PersonalDetailsIcon} />
        <h5 className="healthPrimary">{userDetailOfPrimary?.spouseName}</h5>
      </div>
      </div>
      {(primarySpouseMember && api) ? (
        <ClientFormSumComponent
          primaryCareMember={primarySpouseMember}
          api={api}
        ></ClientFormSumComponent>
      ) : <p className="mx-3"> (Not provided) </p>}
      </>}
      </div>
      <div className="padding-8">
       <ul className="ps-2 pt-3"><li className="fw-bold">Specialists</li></ul> 
      <CommProfSum api={api} memberId={userId} proTypeId={'11'} profName={"Specialist"} userDetailOfPrimary={userDetailOfPrimary?.memberName} />
      <CommProfSum api={api} memberId={spouseId} proTypeId={'11'} profName={"Specialist"}  userDetailOfPrimary={userDetailOfPrimary?.spouseName} />
      </div>
      <HealthInsurencePlanSumComponent memberId={userId} spouseId={spouseId} userDetailOfPrimary={userDetailOfPrimary} healthInsurence={healthInsurence} secondhealthInsurance={secondhealthInsurance}></HealthInsurencePlanSumComponent>
      <FamilyMedicalSumHistoryComponent userDetailOfPrimary={userDetailOfPrimary} primaryPersonalMed={primaryPersonalMed} spousePersonalMed={spousePersonalMed} personalLifestyle={personalLifestyle} spouseMedicalHistory={spouseMedicalHistory} personalMedicalHistory={personalMedicalHistory}  spouseFamilyMedicalHistory={spouseFamilyMedicalHistory} familyMedicalHistory={familyMedicalHistory} userId={userId} spouseCurrentLifeStyle={spouseCurrentLifeStyle}></FamilyMedicalSumHistoryComponent>
    </div>
  );
};

export default HealthInfo;
